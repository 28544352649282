.kPtnos  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fcf3e3;
   margin-bottom:0;
  }
  .fFYyFP a {
    display: flex;
    justify-content: space-between;
    text-align: center;

  }
  
    .kJqPpt {
      display: flex;
      align-items: center;
      justify-content:space-between;
    }
    .deFzym {
      background-color: #fcf3e3;
     width: auto;
    }
    .kPtnos img{
      height:70px;
     
      margin-bottom: -2px;
     margin-top: 2px;
     margin-left: 2px;
     }