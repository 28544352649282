.portadas {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 60px;
    padding: 20px;
    background-color: #fcf3e3;
  }
  
  .portada-item {
    position: relative;
    width: 100%;
    padding-bottom: 100%; /* Esto hace que los contenedores sean cuadrados */
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 180px;
  }
  
  .portada-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Esto hace que las imágenes mantengan su relación de aspecto */
    border-radius: 8px; /* Para que las imágenes tengan bordes redondeados */
  }
  
  .portada-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .portada-info h3 {
    margin: 0;
    font-size: 1rem;
    text-align: center;
    margin-right: 18px;
  }
  
  .portada-info p {
   display: none;
    margin: 0;
    font-size: 1rem;
  }

.description p:hover {
    display: flex;
    font-size: medium;
  }