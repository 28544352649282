.portada-container {
  display: flex;
  flex-direction: row; /* Por defecto, el contenido estará en fila */
  margin-top: 0px;
height: 90vh;
margin-top: 10px;
margin-bottom: 10px;
}

.portada-container .acerca-de-nosotros {
  flex: 1; /* Ocupa todo el espacio disponible */
  padding: 40px;
}

.portada-container .imagen {
  flex: 1; /* Ocupa todo el espacio disponible */
  text-align: center; /* Centra la imagen */
}
.portada-container h1{
  font-size: 23px;
  text-align: center;
}
.portada-container .imagen img {
  max-width: 100%; /* La imagen no sobrepasa su contenedor */
  height: 100%; /* Se mantiene la relación de aspecto */
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .portada-container {
    flex-direction: column; 
    height: auto;/* El contenido se apila verticalmente en dispositivos móviles */
  }

  .portada-container .imagen {
    text-align: center; /* Centra la imagen en dispositivos móviles */
  }
}
