.App {
  box-sizing: border-box;
  margin: auto;
  font-family:'Times New Roman', Times, serif;
 
}
.iSCxFL{
  background-color:rgb(252, 243, 227);

}


.cover .img{

margin-left: 10px;
  
 

}
.kJqPpt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}



