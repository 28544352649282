.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 100px;
  padding: 30px;
  background-color: #7a9e89;
  margin: auto;
}

.gallery-item {
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* Que los contenedores sean cuadrados */
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 180px;
}

.gallery-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Que las imágenes mantengan su relación de aspecto */
  border-radius: 180px; /* Bordes redondos */
}

.image-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0px;
  background-color: rgba(196, 219, 192, 0.58); /* Fondo semi-transparente */
  border-radius: 0 0 180px 180px; /* Bordes redondos en la parte inferior */
  text-align: center;
}

.image-info h3 {
  margin-top: 0;
  margin-bottom: 5px;
 margin:auto;
  font-size:16px
}

.image-info p {
  margin-bottom: 0;
  
}
@media (max-width:768px) {
  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 60px;
    padding: 20px;
    background-color: #7a9e89;
  }
}
  


