.banner {
    width:auto;

  }
  
  .banner img {
    width: 100%;
    height: 90vh;
    display: block;
  }
  @media (max-width: 768px) {
    .banner {
      width:auto;
      height: auto;
     margin: auto;
    }
    .banner img{
     max-width: fit-content;
      height: auto;
     
    }
  }